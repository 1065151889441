@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #e8eff1;
  @apply my-8
}

.title {
  text-align: center;
  font-weight: normal;
  font-family: 'Pacifico', cursive;
  color: #01546B;
  font-size: 3rem;
}

.ant-layout-content {
  margin: 0 auto;
  max-width: 1024px;
}

.ant-btn-primary {
  color: #01546B;
}